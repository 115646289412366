<template>
  <!--begin: Wizard Body-->
  <div class="row justify-content-center my-10 px-8 my-lg-15 px-lg-10">
    <div v-if="isLoaded" class="col-xl-12 col-xxl-12">
      <!--begin: Form-->
      <form class="form" id="kt_form" v-on:submit.prevent="onSubmitRegister">
        <h3 class="mb-10 font-weight-bold text-dark">
          Firma erfassen
        </h3>
        <ValidatedInput
          name="Firmenname"
          v-model="company.company_name"
          :v="$v.company.company_name"
          errorMessage="Das ist ein Pflichtfeld"
        />
        <div class="row">
          <div class="col-xl-6">
            <ValidatedInput
              name="Strasse"
              v-model="company.street"
              :v="$v.company.street"
              errorMessage="Das ist ein Pflichtfeld"
            />
          </div>
          <div class="col-xl-6">
            <ValidatedInput
              name="Nummer"
              v-model="company.street_nr"
              :v="$v.company.street_nr"
              errorMessage="Das ist ein Pflichtfeld"
            />
          </div>
        </div>
        <div class="form-group">
          <label>Ortschaft</label>
          <b-form-input
            class="form-control form-control-lg form-control-solid"
            list="localities-list-filter-id"
            v-model="$v.company.locality.$model"
            :state="validateCompanyState('locality')"
            aria-describedby="input-locality-feedback"
          ></b-form-input>
          <datalist id="localities-list-filter-id">
            <option v-for="locality in getLocalities" v-bind:key="locality.id"
              >{{ formatLocality(locality) }}
            </option>
          </datalist>
          <b-form-invalid-feedback id="input-locality-feedback">
            Das ist keine gültige Ortschaft.
          </b-form-invalid-feedback>
        </div>
        <h3 class="mb-10 mt-10 font-weight-bold text-dark">
          Benutzerkonto
        </h3>
        <div class="row">
          <div class="col-xl-6">
            <ValidatedInput
              name="Vorname"
              v-model="userProfile.first_name"
              :v="$v.userProfile.first_name"
              errorMessage="Das ist ein Pflichtfeld"
            />
          </div>
          <div class="col-xl-6">
            <ValidatedInput
              name="Nachname"
              v-model="userProfile.last_name"
              :v="$v.userProfile.last_name"
              errorMessage="Das ist ein Pflichtfeld"
            />
          </div>
        </div>
        <div class="row">
          <div class="col-xl-12">
            <div class="form-group">
              <label>Email</label>
              <b-form-input
                class="form-control form-control-lg form-control-solid"
                type="text"
                v-model="$v.userProfile.email.$model"
                :state="validateUserProfileState('email')"
                aria-describedby="input-live-feedback"
              />
              <b-form-invalid-feedback id="input--live-feedback">
                {{ getEmailError() }}
              </b-form-invalid-feedback>
            </div>
          </div>
        </div>
        <div class="form-group">
          <label>Password</label>
          <b-form-input
            class="form-control form-control-lg form-control-solid"
            type="password"
            v-model="$v.userProfile.password1.$model"
            :state="validateUserProfileState('password1')"
            aria-describedby="input-live-feedback"
          />
          <password-meter
            :password="$v.userProfile.password1.$model"
            @score="onPasswordScore"
          />
          <b-form-invalid-feedback id="input--live-feedback">
            {{ getPasswordError() }}
          </b-form-invalid-feedback>
          <b-form-text
            >Das Passwort muss mindestens 9 Zeichen lang sein und mindestens
            eine Zahl, einen Kleinbuchstaben und einen Großbuchstaben
            enthalten.</b-form-text
          >
        </div>
        <ValidatedInput
          name="Passwort bestätigen"
          v-model="userProfile.password2"
          :v="$v.userProfile.password2"
          type="password"
          errorMessage="Die Bestätigung muss mit ihrem Passwort übereinstimmen"
        />
        <h3 class="mb-10 mt-10 font-weight-bold text-dark">
          Einladungscode
        </h3>
        <div class="form-group">
          <ValidatedInput
            name=""
            v-model="userProfile.invitation_code"
            :v="$v.userProfile.invitation_code"
            errorMessage="Der Einladungscode ist ungültig."
            help-text="Bitte beachten Sie, dass Sie einen Einladungscode nur bei der Registrierung verwenden können."
          />
        </div>
        <div class="form-group">
          <!--          <b-form-checkbox-->
          <!--            id="checkbox-1"-->
          <!--            v-model="$v.userProfile.acceptConditions.$model"-->
          <!--            name="checkbox-1"-->
          <!--            class="checkbox"-->
          <!--            aria-describedby="input-live-feedback"-->
          <!--            :state="false"-->
          <!--          />-->
          <label class="checkbox mb-0">
            <input
              type="checkbox"
              name="agree"
              v-model="$v.userProfile.acceptConditions.$model"
            />
            <span class="mr-2"></span>
            Ich habe die <a href="#" class="ml-1 mr-1">AGB</a>verstanden und
            akzeptiert.
          </label>
          <b-form-invalid-feedback
            :state="validateUserProfileState('acceptConditions')"
          >
            Bitte akzeptieren Sie unsere AGB.
          </b-form-invalid-feedback>
        </div>
        <!--begin: Actions -->
        <div class="form-group d-flex flex-wrap pb-lg-0 pb-3">
          <button
            ref="kt_register_submit"
            class="btn btn-primary font-weight-bolder font-size-h6 px-8 py-4 my-3 mr-4"
            style="width:150px;"
            data-wizard-type="action-submit"
          >
            Senden
          </button>
          <button
            type="button"
            class="btn btn-light-primary font-weight-bolder font-size-h6 px-8 py-4 my-3"
            @click="$router.push({ name: 'login' })"
          >
            Abbrechen
          </button>
        </div>
        <!--end: Actions -->
      </form>
      <!--end: Form-->
    </div>
  </div>
  <!--end: Wizard Body-->
</template>

<style lang="scss">
@import "@/assets/sass/pages/wizard/wizard-1.scss";
</style>

<script>
import { mapState, mapGetters } from "vuex";
import { REGISTER, RESET_AUTH } from "@/store/auth.module";
import Swal from "sweetalert2";

import { validationMixin } from "vuelidate";
import { required, email, sameAs } from "vuelidate/lib/validators";
import { authFormMixin } from "@/mixins/authFormMixin";
import { alertMixin } from "@/mixins/alertMixin";
import { reCaptchaMixin } from "@/mixins/reCaptchaMixin";
import { localityValidationMixin } from "@/mixins/localityValidationMixin";
import ValidatedInput from "@/components/ValidatedInput";
import passwordMeter from "vue-simple-password-meter";
import { LOAD_LOCALITIES } from "@/store/localities.module";

function isEmailUniqueValidation() {
  return this.isEmailUniqueValid();
}

function isPasswordValidValidation() {
  return this.isPasswordValid();
}

function isInvitationCodeValidValidation() {
  return this.isInvitationCodeValid();
}

function passwordScoreValidation() {
  return this.passwordScoreValidation();
}

function localityValidation(value) {
  return this.checkLocality(value);
}

export default {
  components: { ValidatedInput, passwordMeter },
  mixins: [
    validationMixin,
    authFormMixin,
    alertMixin,
    reCaptchaMixin,
    localityValidationMixin
  ],
  name: "Register",
  data() {
    return {
      userProfile: {
        first_name: "",
        last_name: "",
        email: "",
        password1: "",
        password2: "",
        invitation_code: "",
        acceptConditions: false
      },
      company: {
        company_name: "",
        street: "",
        street_nr: "",
        locality: ""
      },
      isLoaded: false
    };
  },
  validations: {
    userProfile: {
      first_name: {
        required
      },
      last_name: {
        required
      },
      email: {
        required,
        email,
        isEmailUniqueValidation
      },
      password1: {
        required,
        passwordScoreValidation,
        isPasswordValidValidation
      },
      password2: {
        required,
        sameAsPassword: sameAs("password1")
      },
      invitation_code: {
        isInvitationCodeValidValidation
      },
      acceptConditions: {
        accepted: val => val === true
      }
    },
    company: {
      company_name: {
        required
      },
      street: {
        required
      },
      street_nr: {
        required
      },
      locality: {
        required,
        localityValidation
      }
    }
  },
  computed: {
    ...mapState({
      errors: state => state.auth.errors
    }),
    ...mapGetters(["getLocalities"])
  },
  async mounted() {
    this.loadData();
    await this.$recaptchaLoaded();
    this.$recaptchaInstance.showBadge();
  },
  methods: {
    loadData() {
      this.$store.dispatch(LOAD_LOCALITIES).then(() => {
        this.isLoaded = true;
      });
    },
    validateUserProfileState(name) {
      const { $dirty, $error } = this.$v.userProfile[name];
      return $dirty ? !$error : null;
    },
    validateCompanyState(name) {
      const { $dirty, $error } = this.$v.company[name];
      return $dirty ? !$error : null;
    },
    getEmailError() {
      if (!this.$v.userProfile.email.isEmailUniqueValidation) {
        return "Ein Benutzer ist bereits mit dieser Email Adresse registriert.";
      }
      return "Das ist ein Pflichtfeld und muss eine gültige Email Adresse sein";
    },
    getPasswordError() {
      if (!this.$v.userProfile.password1.passwordScoreValidation) {
        return this.passwordScoreError;
      }
      if (!this.$v.userProfile.password1.isPasswordValidValidation) {
        return this.invalidPasswordError;
      }
    },
    async onSubmitRegister() {
      // clear existing server errors
      await this.$store.dispatch(RESET_AUTH);

      this.$v.userProfile.$touch();
      this.$v.company.$touch();
      if (this.$v.userProfile.$anyError || this.$v.company.$anyError) {
        this.showCheckProvidedDataAlert();
        return;
      }

      // Check reCaptcha
      const isRecaptchaValid = await this.isReCaptchaValid();
      if (!isRecaptchaValid) {
        this.showReCaptchaError();
        return;
      }

      // set spinner to submit button
      const submitButton = this.$refs["kt_register_submit"];
      submitButton.classList.add("spinner", "spinner-light", "spinner-right");

      setTimeout(() => {
        // Replace the locality name with the corresponding id
        const companyPayload = Object.assign({}, this.company);
        companyPayload.locality = this.getLocalityIdForName(
          this.company.locality
        );

        // send registration
        this.$set(this.userProfile, "company", companyPayload);
        this.$store
          .dispatch(REGISTER, this.userProfile)
          .then(() => {
            Swal.fire({
              title: "",
              text:
                "Sie wurden erfolgreich registriert. Es wurde ein Email an " +
                this.userProfile.email +
                " gesendet. Bitte folgen sie den Instruktionen in der Email um Ihr Konto zu aktivieren.",
              icon: "success",
              customClass: {
                confirmButton: "btn btn-secondary"
              },
              willClose: () => {
                this.$router.push({ name: "login" });
              }
            });
          })
          .catch(() => {
            this.$v.userProfile.$touch();
            // console.error(this.errors);
            this.showCheckProvidedDataAlert();
            // Swal.fire({
            //   title: "",
            //   text:
            //     "Ops. Etwas ist schiefgelaufen. Bitte versuchen Sie es später nochmals!",
            //   icon: "error",
            //   customClass: "btn btn-secondary",
            //   heightAuto: false
            // });
          });

        submitButton.classList.remove(
          "spinner",
          "spinner-light",
          "spinner-right"
        );
      }, 2000);
    },
    isEmailUniqueValid() {
      if (this.errors === null) {
        return true;
      }
      if ("email" in this.errors.response.data) {
        return false;
      }
      return true;
    },
    isPasswordValid() {
      if (this.errors === null) {
        return true;
      }
      if ("password1" in this.errors.response.data) {
        return false;
      }
      return true;
    },
    isInvitationCodeValid() {
      if (this.errors === null) {
        return true;
      }
      if ("invitation_code" in this.errors.response.data) {
        return false;
      }
      return true;
    }
  }
};
</script>
